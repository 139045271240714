import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Badge,
  Row,
  Col,
  Avatar,
  Input,
  message,
  Modal,
  Result,
} from "antd";
import {
  ArrowLeftIcon,
  CouponCart,
  DeleteIcon,
  LocationIcon,
  MoreIcon,
} from "../asset/icons/icons";
import Table from "react-bootstrap/Table";
// import carticonimage1 from "../asset/carticonimage1.jpg";
import EmptyCart from "../asset/EmptyCart.svg";
import opayimg from "../asset/opayimg.png"
// import nibbleslogo from "../asset/nibbleslogo.jpg";
// import { FormatNumber } from "../Utils/cartmgt";
import { useDispatch, useSelector } from "react-redux";
import {
  createOpayPayment,
  deleteItemsInCart,
  getAllAvailableLocation,
  getItemsInCart,
  postVerifyTransaction,
  updateItemsInCart,
} from "../redux/Actions";
import { getCartReducer } from "../redux/Reducer/cartReducer";
import { getPaymentReducer } from "../redux/Reducer/paymentReducer";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import ChangeOrderLocationModal from "./changeOrderLocation";
import VerifyAccountModal from "./VerifyAccountModal";
// import PaystackPaymentButton from "./payStackPayment";
import PayStackButtonMethod from "./paystackButtonMethod";
// import axios from "axios";
// import getOpayPaymentURL from "../Utils/getOpayPaymentURL";
// import getOpayKey from "../Utils/getOpayKeys";
// import axios from "axios";

const NewCart = ({
  initialCartItems,
  authuserdetails,
  handleVerifyStatus,
  userProfileDetails,
  setSelectedKey,
  onClose,
  setOpen,
  setConfettiActive,
}) => {
  const [modal2Open, setModal2Open] = useState(false);
  // const [locationDetails, setLocationDetails] = useState({
  //   location_id:
  //     userProfileDetails?.data?.business?.state === "Abuja"
  //       ? 2
  //       : userProfileDetails?.data?.business?.state === "Port-Harcourt"
  //       ? 3
  //       : 1,
  //   location_name: userProfileDetails?.data?.business?.state,
  // });
  const [locationDetails, setLocationDetails] = useState("");
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFlutterModalOpen, setIsFlutterModalOpen] = useState(false);
  const {
    // deleteCartItems,
    // updateCartItems,
    verifyTransaction,
    availableLocations,
    isloadingAvailableLocations,
    isloadingverifyTransaction,
  } = useSelector(getCartReducer);

  const { paymentLoading } = useSelector(getPaymentReducer);
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState(initialCartItems || []);
  const [subtotal, setSubtotal] = useState(0);
  // const [serviceFee, setServiceFee] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);

  // const userData = JSON.parse(localStorage.getItem('user'));
  // const userType = userData?.user?.user_type_id ?? null

  const handleQuantityChange = (value, itemId) => {
    const updatedCartItems = cartItems?.map((item) =>
      item?.id === itemId ? { ...item, qty: parseInt(value) || 0 } : item
    );
    dispatch(updateItemsInCart(itemId, { qty: parseInt(value) || 0 }));
    setCartItems(updatedCartItems);
  };

  const handleDeleteItem = (itemId) => {
    const updatedCartItems = cartItems?.filter((item) => item?.id !== itemId);
    dispatch(deleteItemsInCart(itemId));
    setCartItems(updatedCartItems);
  };

  const calculateTotals = () => {
    const newSubtotal = (cartItems || []).reduce(
      (acc, item) => acc + item?.product?.amount * item?.qty,
      0
    );
    // const newServiceFee = newSubtotal * 0.075;
    // const newTotalCost = newSubtotal + newServiceFee;
    const newTotalQuantity = cartItems.reduce(
      (acc, item) => acc + item?.qty,
      0
    );

    setSubtotal(newSubtotal);
    // setServiceFee(newServiceFee);
    setTotalCost(newSubtotal);
    setTotalQuantity(newTotalQuantity);
    if (userProfileDetails?.data?.user_type_id !== 5) {
      if (newTotalQuantity < 50) {
        message.warning(
          "You cannot place an order with less than 50 quantity."
        );
        setIsOrderButtonDisabled(true);
      } else {
        setIsOrderButtonDisabled(false);
      }
    } else {
    }
  };

  const FormatNumber = (number) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(number);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    //   setIsModalOpen(false);
    // debugger
    setSelectedKey("6");
    setIsModalOpen(false);
    setOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showFlutterModal = () => {
    setIsFlutterModalOpen(true);
  };
  const handleFlutterOk = () => {
    setIsFlutterModalOpen(false);
  };
  const handleFlutterCancel = () => {
    setIsFlutterModalOpen(false);
  };

  const handleChangeLocation = () => {
    setModal2Open(true);
    dispatch(getAllAvailableLocation());
  };

  const handleLocationChange = (locationid, locationname) => {
    // debugger
    // console.log(locationid, "getavailloc");
    // console.log(locationname, "getavaillocname");
    setLocationDetails({
      ...locationDetails,
      location_id: locationid,
      location_name: locationname,
    });
    setModal2Open(false);
  };

  // const config = {
  //   public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
  //   tx_ref: `NB${Date.now()}`,
  //   amount: totalCost,
  //   currency: "NGN",
  //   payment_options: "card,mobilemoney,ussd",
  //   customer: {
  //     email: authuserdetails.email,
  //     phone_number: authuserdetails.phone,
  //     name: authuserdetails.name,
  //   },
  //   customizations: {
  //     title: "Nibbles Bakery",
  //     description: "Payment for items in cart",
  //     logo: nibbleslogo,
  //   },
  // };

  //   const myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer OPAYPUB16360242611890.028229908152910155");
  // myHeaders.append("MerchantID", "256621110429740");
  // myHeaders.append("Content-Type", "application/json");

  // const raw = JSON.stringify({
  //   "amount": {
  //     "currency": "NGN",
  //     "total": 42500000
  //   },
  //   "country": "NG",
  //   "product": {
  //     "description": "Soft and fluffy tea buns, freshly baked to perfection with a sweet and buttery flavour. Perfect for snacking or pairing with your favourite hot beverage.",
  //     "name": "Hollie Mante Jr."
  //   },
  //   "reference": "NB173320156668308",
  //   "returnUrl": "http://localhost:3000/dashboard",
  //   "callbackUrl": "https://your-call-back-url",
  //   "userInfo": {
  //     "userEmail": "angelita44@yahoo.com",
  //     "userId": 382,
  //     "userMobile": "574-861-1939",
  //     "userName": "Hollie Mante Jr."
  //   }
  // });

  // const requestOptions = {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: "follow"
  // };

  // const checkoutItems = cartItems?.map((item) => ({
  //   // productId: item?.product_id,
  //   description: item?.product?.description,
  //   name: item?.user?.name,
  //   // price: item?.product?.amount, // Assuming price is in the lowest currency unit (e.g., Kobo for Naira)
  //   // quantity: item?.qty,
  //   // imageUrl: item?.product?.images[0]?.url
  // }));

  // console.log('Checkout Items:', checkoutItems); // To verify the transformed array

  // const handleReturnURL = () => {
  //   if (window.location.hostname === 'localhost') {
  //     return "http://localhost:3000/dashboard";
  //   } else {
  //     return "https://app.nibblesbakery.ng/dashboard";
  //   }
  // }

  // const opayConfig = {
  //   // reference: `NB${Date.now()}`,
  //   // mchShortName: "Nibbles Bakery",
  //   // // productName: "Apple AirPods Pro",
  //   // // productDesc: "The best wireless earphone in history",
  //   // userPhone: authuserdetails.phone,
  //   // // userRequestIp: "123.123.123.123",
  //   // amount: `${totalCost + 0+0}`,
  //   // currency: "NGN",
  //   // payTypes: ["BalancePayment", "BonusPayment", "OWealth"],
  //   // payMethods: ["account", "qrcode", "bankCard", "bankAccount", "bankTransfer", "bankUSSD"],
  //   // callbackUrl: "http://localhost:3000/dashboard",
  //   // returnUrl: "http://localhost:3000/dashboard",
  //   // expireAt: "10"

  //   amount: {
  //     currency: "NGN",
  //     total: parseInt(totalCost * 100),
  //   },
  //   country: "NG",
  //   // payMethod: "BankCard",
  //   payMethod: "OpayWalletNg",
  //   product: checkoutItems,
  //   reference: `NB${Date.now()}`,
  //   returnUrl: handleReturnURL(),
  //   // callbackUrl: "https://your-call-back-url",
  //   userInfo: {
  //     userEmail: authuserdetails.email,
  //     userId: authuserdetails.id,
  //     userMobile: authuserdetails.phone,
  //     userName: authuserdetails.name,
  //   },
  //   // "amount": {
  //   //     "currency": "NGN",
  //   //     "total": 42500000
  //   // },
  //   // "country": "NG",
  //   // "product": {
  //   //     "description": "Soft and fluffy tea buns, freshly baked to perfection with a sweet and buttery flavour. Perfect for snacking or pairing with your favourite hot beverage.",
  //   //     "name": "Hollie Mante Jr."
  //   // },
  //   // "reference": "NB173320156668308",
  //   // "returnUrl": "http://localhost:3000/dashboard",
  //   // "callbackUrl": "https://your-call-back-url",
  //   // "userInfo": {
  //   //     "userEmail": "angelita44@yahoo.com",
  //   //     "userId": 382,
  //   //     "userMobile": "574-861-1939",
  //   //     "userName": "Hollie Mante Jr."
  //   // }
  // };

  // const handleFlutterPayment = useFlutterwave(config);

  // const handleVerifyTransaction = (value) => {
  //   dispatch(
  //     postVerifyTransaction({
  //       reference: value.tx_ref,
  //       transaction_id: value.transaction_id,
  //       location_id: locationDetails.location_id,
  //       payment_channel: "flutterwave",
  //       user_type_id: userProfileDetails?.data?.user?.user_type_id,
  //     })
  //   );
  //   // dispatch(clearCartItems());
  //   setIsLoadingPayment(false);
  //   // dispatch(getItemsInCart(authuserdetails.id));
  // };

  const newOpayConfig = {
    platform: "web",
    amount: totalCost,
    location_id: userProfileDetails?.data?.location?.id || locationDetails?.location_id,
    is_direct: userProfileDetails?.data?.user_type_id === 5 ? true : false,
    payment_channel: "opay"
  }
  const handlePlaceOrderDist = (value) => {
    // debugger
    dispatch(
      postVerifyTransaction({
        reference: `NB${Date.now()}`,
        transaction_id:
          Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000,
        location_id:
          locationDetails === ""
            ? userProfileDetails?.data?.location?.id
            : locationDetails?.location_id,
        is_direct: userProfileDetails?.data?.user_type_id === 5 ? true : false,
      })
    );
    // dispatch(clearCartItems());
    setIsLoadingPayment(true);
    // dispatch(getItemsInCart(authuserdetails.id));
  };

  const handleOpayPayment = (value) => {
    setIsLoadingPayment(paymentLoading);
    dispatch(createOpayPayment(newOpayConfig));
    // dispatch(getItemsInCart(authuserdetails.id));
  };
  // const handleOpayPayment = (value) => {
  //   debugger;
  //   if (userProfileDetails?.data?.status === 1) {
  //     setIsLoadingPayment(true);
  //     try {
  //       const response = axios.post(
  //         `${getOpayPaymentURL()}`,
  //         opayConfig,
  //         {
  //           headers: {
  //             "Access-Control-Allow-Origin": '*',
  //             Authorization: 'Bearer '+process.env.REACT_APP_OPAY_PK,
  //             "Content-Type": "application/json",
  //             MerchantId: `${process.env.REACT_APP_OPAY_MERCHANTID}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       );
  //       if (response.message === "SUCCESSFUL") {
  //         // debugger
  //         console.log(response);
  //         dispatch(
  //           postVerifyTransaction({
  //             reference: response?.data?.reference,
  //             transaction_id: response?.data?.orderNo,
  //             location_id: locationDetails,
  //             payment_channel: "opay",
  //             is_direct: false,
  //           })
  //         );
  //         setIsLoadingPayment(false);
  //       } else {
  //         console.log(response);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //     // setIsLoadingFlutter(false);

  // //     fetch("https://testapi.opaycheckout.com/api/v1/international/cashier/create", requestOptions)
  // // .then((response) => response.text())
  // // .then((result) => console.log(result))
  // // .catch((error) => console.error(error));
  //   } else {
  //     showModal();
  //   }
  //   setIsLoadingPayment(false);
  //   // dispatch(getItemsInCart(authuserdetails.id));
  // };

  // Update cartItems when initialCartItems changes
  useEffect(() => {
    setCartItems(initialCartItems || []);
  }, [initialCartItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      calculateTotals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  useEffect(() => {
    if (verifyTransaction?.status === true) {
      // debugger
      // cartItems.map(item =>
      //         item.id === updateid ? { ...item, qty: updateQuantity } : item
      //     )
      dispatch(getItemsInCart(authuserdetails.id));
      onClose();
      setConfettiActive(true);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyTransaction]);
  //   console.log(initialCartItems, "initialCartItems")
  //   console.log(cartItems, "cartItems")
  // console.log(locationDetails.location_id, "locationid")
  console.log(locationDetails, "locationid");

  return (
    <Card>
      <div className="dashboardcardpriceandcartdiv">
        <p
          className="typography"
          style={{ fontSize: "18px", fontWeight: "500", color: "#1A202C" }}
        >
          My Cart
        </p>
        <Button
          type="text"
          style={{ margin: "-1.5em 0px 0px 0px", padding: "0px" }}
        >
          <MoreIcon />
        </Button>
      </div>

      {cartItems?.code !== "ERR_BAD_REQUEST" ? (
        cartItems?.length !== 0 ? (
          <div className="">
            <Badge size="small" count={cartItems?.length} color="#BDC167">
              <p
                className="typography"
                style={{ color: "#252A4D", fontWeight: 500, fontSize: "16px" }}
              >
                Orders
              </p>
            </Badge>
            {/* Rest of your JSX for displaying cart items */}
            <Row>
              <Col span={24}>
                <Table responsive borderless size="sm">
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th className="cart-table-header-style">Item</th>
                      <th className="cart-table-header-style">Qty</th>
                      <th className="cart-table-header-style">Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* <Divider /> */}
                  <tbody>
                    {cartItems?.length !== 0 ? (
                      cartItems?.map((item, i) => (
                        <tr key={item.id}>
                          <td>
                            <div className="d-flex align-items-center gap-1">
                              <div>
                                <Avatar
                                  size="medium"
                                  icon={
                                    <img
                                      src={item?.product?.images[0]?.url}
                                      alt="carticonimage1"
                                      draggable={false}
                                    />
                                  }
                                />
                              </div>
                              <div>
                                <p className="typography carttextproductnametypography">
                                  {item?.product?.name}
                                </p>
                                <p className="typography carttextproductpricetypography">
                                  {FormatNumber(item?.product?.amount)}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            {/* <Input min={1} value={item?.qty} onChange={(e) => handleQuantityChange(e.target.value, item.id)} placeholder="Qty" /> */}
                            <Input
                              min={1}
                              value={item?.qty}
                              type="number"
                              placeholder="Qty"
                              onChange={(e) =>
                                handleQuantityChange(e.target.value, item?.id)
                              }
                              style={{ width: "65px" }}
                            />
                          </td>
                          <td>
                            <div className="dashboardcardpriceandcartdiv">
                              <p className="typography carttextproductQpricetypography">
                                {FormatNumber(
                                  item?.product?.amount * item?.qty
                                )}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="dashboardcardpriceandcartdiv">
                              <Button
                                type="text"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  height: "fit-content",
                                }}
                                onClick={() => handleDeleteItem(item?.id)}
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : cartItems?.code === "ERR_BAD_REQUEST" ? (
                      <tr>
                        <td colSpan="4">
                          <p>No item in cart</p>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div>
                  <p
                    className="typography"
                    style={{ fontWeight: 500, marginTop: "10px" }}
                  >
                    Nearest Pickup to you
                  </p>
                </div>

                <div className="dashboardcardpriceandcartdiv">
                  {locationDetails === "" ? (
                    <p className="cartnearestlocation">
                      <LocationIcon />
                      {`${userProfileDetails?.data?.location?.name} - ${userProfileDetails?.data?.location?.address}`}
                    </p>
                  ) : (
                    <p className="cartnearestlocation">
                      <LocationIcon />
                      {`${locationDetails?.location_name}`}
                    </p>
                  )}
                  <Button
                    size="small"
                    className="cartnearestlocationbutton"
                    onClick={handleChangeLocation}
                  >
                    Change
                  </Button>
                  <ChangeOrderLocationModal
                    modal2Open={modal2Open}
                    setModal2Open={setModal2Open}
                    availableLocations={availableLocations}
                    isloadingAvailableLocations={isloadingAvailableLocations}
                    handleLocationChange={handleLocationChange}
                    defaultsel={
                      locationDetails === ""
                        ? userProfileDetails?.data?.location?.id
                        : locationDetails?.location_id
                    }
                  />
                </div>

                <div>
                  <p className="typography cartpickingwhenready">
                    You will be picking up your order from this location once it
                    is ready.
                  </p>
                </div>

                {/* <div style={{ display: "flex" }}>
                                    <Button size='small' className='cartadddetailbutton'>
                                        Add Details
                                    </Button>
                                    <Button size='small' className='cartadddetailbutton'>
                                        Add Note
                                    </Button>
                                </div> */}
                <div style={{ marginTop: "2em" }}>
                  <div className="cartpricesummariesdiv">
                    <p className="cartpricesummariestypog1">Discount</p>
                    <p className="typography">₦0</p>
                  </div>

                  <div className="cartpricesummariesdiv">
                    <p className="cartpricesummariestypog1">Sub total</p>
                    <p className="typography">{FormatNumber(subtotal)}</p>
                  </div>

                  {/* <div className="cartpricesummariesdiv">
                    <p className="cartpricesummariestypog1">Service Fee</p>
                    <p className="typography">{FormatNumber(serviceFee)}</p>
                  </div> */}

                  <div className="cartpricesummariesdiv">
                    <p className="cartpricesummariestypog1 cartprices-total-txt">
                      Total
                    </p>
                    <p className="typography  cartprices-total-txt">
                      {FormatNumber(totalCost)}
                    </p>
                  </div>
                  {userProfileDetails?.data?.user_type_id === 5 ? (
                    <div className="mt-2">
                      <Button
                        block
                        size={"large"}
                        className="placeordercartbutton typography"
                        onClick={handlePlaceOrderDist}
                        disabled={
                          isLoadingPayment || isloadingverifyTransaction
                        }
                      >
                        Place Order
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <div className="cartpricesummariesdiv">
                        <Button
                          block
                          size={"large"}
                          className="cartcouponbutton typography"
                        >
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center gap-2">
                              <CouponCart
                                style={{ backgroundColor: "#BDC167" }}
                              />
                              <div>
                                <span style={{ marginLeft: "1em" }}>
                                  Have a coupon code?
                                </span>
                              </div>
                            </div>
                            <div className="">
                              <ArrowLeftIcon />
                            </div>
                          </div>
                        </Button>
                      </div>

                      <div className="mt-2">
                        <Button
                          block
                          size={"large"}
                          className="placeordercartbutton typography"
                          onClick={handleOpayPayment}
                          disabled={isOrderButtonDisabled || isLoadingPayment}
                          icon={
                            <img
                              src={
                                opayimg
                              }
                              alt="Opay"
                              style={{ height: "2.7em" }}
                            />
                          }
                        >
                          Pay with Opay
                        </Button>
                      </div>

                      <div>
                        <PayStackButtonMethod
                          userProfileDetails={userProfileDetails}
                          setIsLoadingPayment={setIsLoadingPayment}
                          isLoadingPayment={isLoadingPayment}
                          showModal={showModal}
                          authuserdetails={authuserdetails}
                          totalCost={totalCost}
                          isOrderButtonDisabled={isOrderButtonDisabled}
                          postVerifyTransaction={postVerifyTransaction}
                          dispatch={dispatch}
                          locationDetails={
                            locationDetails === ""
                              ? userProfileDetails?.data?.location?.id
                              : locationDetails?.location_id
                          }
                        />
                      </div>
                      <div className="cartpricesummariesplaceorderdiv">
                        <Button
                          block
                          size={"large"}
                          className="placeordercartbutton typography"
                          // onClick={() => {
                          //   if (userProfileDetails?.data?.status === 1) {
                          //     setIsLoadingPayment(true);
                          //     handleFlutterPayment({
                          //       callback: (response) => {
                          //         // console.log(response);
                          //         handleVerifyTransaction(response);
                          //         closePaymentModal(); // this will close the modal programmatically
                          //         setIsLoadingPayment(false);
                          //       },
                          //       onClose: () => {
                          //         setIsLoadingPayment(false);
                          //       },
                          //     });
                          //     // setIsLoadingFlutter(false);
                          //   } else {
                          //     showModal();
                          //   }
                          // }}
                          onClick={showFlutterModal}
                          disabled={isOrderButtonDisabled || isLoadingPayment}
                          icon={
                            <img
                              src={
                                "https://logosmarcas.net/wp-content/uploads/2022/06/Flutterwave-Simbolo.png"
                              }
                              alt="Paystack"
                              style={{ height: "1.7em" }}
                            />
                          }
                        >
                          Pay with FlutterWave
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <img
            src={EmptyCart}
            alt="no order history"
            style={{ width: "100%" }}
            draggable={false}
          />
        )
      ) : (
        <img
          src={EmptyCart}
          alt="no order history"
          style={{ width: "100%" }}
          draggable={false}
        />
      )}
      <VerifyAccountModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Modal
        title=""
        open={isFlutterModalOpen}
        onOk={handleFlutterOk}
        onCancel={handleFlutterCancel}
        footer={[
          <Button type="primary" onClick={handleFlutterOk}>
            OK
          </Button>,
        ]}
      >
        <Result
          status="warning"
          title="Service Currently Not Available, Kindly use another payment option."
        // extra={
        //   <Button type="primary" key="console">
        //     Go Console
        //   </Button>
        // }
        />
      </Modal>
    </Card>
  );
};
export default NewCart;
