import { CREATE_OPAY_PAYMENT, CREATE_OPAY_PAYMENT_FAILURE, CREATE_OPAY_PAYMENT_SUCCESS, RESET_PAYMENT } from "../ActionTypes";


const INIT_STATE = {
    paymentLoading:false,
    createdPayment:false,
};
function PaymentReducer(state = INIT_STATE, action) {
    switch (action.type) {
        
        case CREATE_OPAY_PAYMENT: {
            // debugger
            return {
                ...state,
                paymentLoading:true,
                createdPayment:false,
            }
        }
        case CREATE_OPAY_PAYMENT_SUCCESS: {
            // debugger
            return {
                ...state,
                paymentLoading:false,
                createdPayment:true,
            };
        }
        case CREATE_OPAY_PAYMENT_FAILURE: {
            return {
                ...state,
                paymentLoading:false,
                createdPayment:false,
            };
        }
       
        case RESET_PAYMENT: {
            return {
                ...state,
                paymentLoading:false,
                createdPayment:false,
                
            };
        }
        
        default:
            return state;
    }
}
export default PaymentReducer;

export const getPaymentReducer = state => state.paymentReducer