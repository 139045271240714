import { CREATE_OPAY_PAYMENT, CREATE_OPAY_PAYMENT_FAILURE, CREATE_OPAY_PAYMENT_SUCCESS, RESET_PAYMENT } from "../ActionTypes";

export const createOpayPayment = (payload) => ({
    type: CREATE_OPAY_PAYMENT,
    payload
});
export const createOpayPaymentSuccess = (payload) => ({
    type: CREATE_OPAY_PAYMENT_SUCCESS,
    payload
});
export const createOpayPaymentFailure = (payload) => ({
    type: CREATE_OPAY_PAYMENT_FAILURE,
    payload
});
export const resetPayment = (payload) => ({
    type: RESET_PAYMENT,
    payload
});