import { all, call, fork, takeEvery } from "redux-saga/effects";
import requestFunction from "../../Utils/sagaGenericFunction";
import { createOpayPaymentFailure, createOpayPaymentSuccess } from "../Actions";
import { CreateOpayPaymentApi } from "../Api";
import { CREATE_OPAY_PAYMENT } from "../ActionTypes";

const createOpayPaymentWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, createOpayPaymentSuccess, createOpayPaymentFailure, CreateOpayPaymentApi, payload);
};
export const createOpayPaymentWatcher = function* () {
    // debugger
    yield takeEvery(CREATE_OPAY_PAYMENT, createOpayPaymentWorker);
};


export default function* paymentSaga() {
    yield all([
        fork(createOpayPaymentWatcher),
    ]);
}