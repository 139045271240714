export const GET_COUNTRY = "GET_COUNTRY"
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS"
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE"

export const SIGN_UP = "SIGN_UP"
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS"
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE"

export const ENTER_OTP = "ENTER_OTP"
export const ENTER_OTP_SUCCESS = "ENTER_OTP_SUCCESS"
export const ENTER_OTP_FAILURE = "ENTER_OTP_FAILURE"

export const RESEND_OTP = "RESEND_OTP"
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS"
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE"

export const SIGN_IN = "SIGN_IN"
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS"
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE"
export const SIGN_OUT_USER = "SIGN_OUT_USER"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE"

export const NEW_PASSWORD = "NEW_PASSWORD"
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS"
export const NEW_PASSWORD_FAILURE = "NEW_PASSWORD_FAILURE"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

export const GET_USER_TYPE = "GET_USER_TYPE"
export const GET_USER_TYPE_SUCCESS = "GET_USER_TYPE_SUCCESS"
export const GET_USER_TYPE_FAILURE = "GET_USER_TYPE_FAILURE"

export const GET_RELATIONSHIP_TYPE = "GET_RELATIONSHIP_TYPE"
export const GET_RELATIONSHIP_TYPE_SUCCESS = "GET_RELATIONSHIP_TYPE_SUCCESS"
export const GET_RELATIONSHIP_TYPE_FAILURE = "GET_RELATIONSHIP_TYPE_FAILURE"

export const GET_NATURE_OF_BUSINESS = "GET_NATURE_OF_BUSINESS"
export const GET_NATURE_OF_BUSINESS_SUCCESS = "GET_NATURE_OF_BUSINESS_SUCCESS"
export const GET_NATURE_OF_BUSINESS_FAILURE = "GET_NATURE_OF_BUSINESS_FAILURE"

export const POST_USER_TYPE = "POST_USER_TYPE"
export const POST_USER_TYPE_SUCCESS = "POST_USER_TYPE_SUCCESS"
export const POST_USER_TYPE_FAILURE = "POST_USER_TYPE_FAILURE"

export const GET_STATE = "GET_STATE"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"
export const GET_STATE_FAILURE = "GET_STATE_FAILURE"

export const GET_LOCAL_GOVERNMENT_BY_STATE_ID = "GET_LOCAL_GOVERNMENT_BY_STATE_ID"
export const GET_LOCAL_GOVERNMENT_BY_STATE_ID_SUCCESS = "GET_LOCAL_GOVERNMENT_BY_STATE_ID_SUCCESS"
export const GET_LOCAL_GOVERNMENT_BY_STATE_ID_FAILURE = "GET_LOCAL_GOVERNMENT_BY_STATE_ID_FAILURE"

export const POST_BUSINESS_DETAILS = "POST_BUSINESS_DETAILS"
export const POST_BUSINESS_DETAILS_SUCCESS = "POST_BUSINESS_DETAILS_SUCCESS"
export const POST_BUSINESS_DETAILS_FAILURE = "POST_BUSINESS_DETAILS_FAILURE"

export const POST_BUSINESS_LOCATION = "POST_BUSINESS_LOCATION"
export const POST_BUSINESS_LOCATION_SUCCESS = "POST_BUSINESS_LOCATION_SUCCESS"
export const POST_BUSINESS_LOCATION_FAILURE = "POST_BUSINESS_LOCATION_FAILURE"

export const POST_BUSINESS_HISTORY = "POST_BUSINESS_HISTORY"
export const POST_BUSINESS_HISTORY_SUCCESS = "POST_BUSINESS_HISTORY_SUCCESS"
export const POST_BUSINESS_HISTORY_FAILURE = "POST_BUSINESS_HISTORY_FAILURE"

export const POST_BUSINESS_CONTACT = "POST_BUSINESS_CONTACT"
export const POST_BUSINESS_CONTACT_SUCCESS = "POST_BUSINESS_CONTACT_SUCCESS"
export const POST_BUSINESS_CONTACT_FAILURE = "POST_BUSINESS_CONTACT_FAILURE"

export const POST_USER_DETAILS = "POST_USER_DETAILS"
export const POST_USER_DETAILS_SUCCESS = "POST_USER_DETAILS_SUCCESS"
export const POST_USER_DETAILS_FAILURE = "POST_USER_DETAILS_FAILURE"

export const POST_BUSINESS_DOCUMENT = "POST_BUSINESS_DOCUMENT"
export const POST_BUSINESS_DOCUMENT_SUCCESS = "POST_BUSINESS_DOCUMENT_SUCCESS"
export const POST_BUSINESS_DOCUMENT_FAILURE = "POST_BUSINESS_DOCUMENT_FAILURE"

export const UPDATE_BUSINESS_DOCUMENT = "UPDATE_BUSINESS_DOCUMENT"
export const UPDATE_BUSINESS_DOCUMENT_SUCCESS = "UPDATE_BUSINESS_DOCUMENT_SUCCESS"
export const UPDATE_BUSINESS_DOCUMENT_FAILURE = "UPDATE_BUSINESS_DOCUMENT_FAILURE"

export const GET_BUSINESS_DOCUMENT = "GET_BUSINESS_DOCUMENT"
export const GET_BUSINESS_DOCUMENT_SUCCESS = "GET_BUSINESS_DOCUMENT_SUCCESS"
export const GET_BUSINESS_DOCUMENT_FAILURE = "GET_BUSINESS_DOCUMENT_FAILURE"

export const POST_BUSINESS_AREA = "POST_BUSINESS_AREA"
export const POST_BUSINESS_AREA_SUCCESS = "POST_BUSINESS_AREA_SUCCESS"
export const POST_BUSINESS_AREA_FAILURE = "POST_BUSINESS_AREA_FAILURE"

export const UPDATE_BUSINESS_AREA = "UPDATE_BUSINESS_AREA"
export const UPDATE_BUSINESS_AREA_SUCCESS = "UPDATE_BUSINESS_AREA_SUCCESS"
export const UPDATE_BUSINESS_AREA_FAILURE = "UPDATE_BUSINESS_AREA_FAILURE"

export const GET_BUSINESS_AREA = "GET_BUSINESS_AREA"
export const GET_BUSINESS_AREA_SUCCESS = "GET_BUSINESS_AREA_SUCCESS"
export const GET_BUSINESS_AREA_FAILURE = "GET_BUSINESS_AREA_FAILURE"

export const GET_ITEMS_IN_CART = "GET_ITEMS_IN_CART"
export const GET_ITEMS_IN_CART_SUCCESS = "GET_ITEMS_IN_CART_SUCCESS"
export const GET_ITEMS_IN_CART_FAILURE = "GET_ITEMS_IN_CART_FAILURE"

export const ADD_ITEMS_IN_CART = "ADD_ITEMS_IN_CART"
export const ADD_ITEMS_IN_CART_SUCCESS = "ADD_ITEMS_IN_CART_SUCCESS"
export const ADD_ITEMS_IN_CART_FAILURE = "ADD_ITEMS_IN_CART_FAILURE"

export const UPDATE_ITEMS_IN_CART = "UPDATE_ITEMS_IN_CART"
export const UPDATE_ITEMS_IN_CART_SUCCESS = "UPDATE_ITEMS_IN_CART_SUCCESS"
export const UPDATE_ITEMS_IN_CART_FAILURE = "UPDATE_ITEMS_IN_CART_FAILURE"

export const DELETE_ITEMS_IN_CART = "DELETE_ITEMS_IN_CART"
export const DELETE_ITEMS_IN_CART_SUCCESS = "DELETE_ITEMS_IN_CART_SUCCESS"
export const DELETE_ITEMS_IN_CART_FAILURE = "DELETE_ITEMS_IN_CART_FAILURE"

export const GET_ALL_AVAILABLE_LOCATION = "GET_ALL_AVAILABLE_LOCATION"
export const GET_ALL_AVAILABLE_LOCATION_SUCCESS = "GET_ALL_AVAILABLE_LOCATION_SUCCESS"
export const GET_ALL_AVAILABLE_LOCATION_FAILURE = "GET_ALL_AVAILABLE_LOCATION_FAILURE"

export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS"
export const CLEAR_CART_ITEMS_SUCCESS = "CLEAR_CART_ITEMS_SUCCESS"
export const CLEAR_CART_ITEMS_FAILURE = "CLEAR_CART_ITEMS_FAILURE"

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT"
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS"
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE"

export const POST_VERIFY_TRANSACTION = "POST_VERIFY_TRANSACTION"
export const POST_VERIFY_TRANSACTION_SUCCESS = "POST_VERIFY_TRANSACTION_SUCCESS"
export const POST_VERIFY_TRANSACTION_FAILURE = "POST_VERIFY_TRANSACTION_FAILURE"

export const GET_ALL_ADMIN_ORDERS = "GET_ALL_ADMIN_ORDERS"
export const GET_ALL_ADMIN_ORDERS_SUCCESS = "GET_ALL_ADMIN_ORDERS_SUCCESS"
export const GET_ALL_ADMIN_ORDERS_FAILURE = "GET_ALL_ADMIN_ORDERS_FAILURE"

export const GET_ALL_ADMIN_ORDERS_BY_STATUS = "GET_ALL_ADMIN_ORDERS_BY_STATUS"
export const GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS = "GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS"
export const GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE = "GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE"

export const GET_ALL_USER_ORDERS = "GET_ALL_USER_ORDERS"
export const GET_ALL_USER_ORDERS_SUCCESS = "GET_ALL_USER_ORDERS_SUCCESS"
export const GET_ALL_USER_ORDERS_FAILURE = "GET_ALL_USER_ORDERS_FAILURE"

export const GET_ADMIN_ARCHIVE_ORDERS = "GET_ADMIN_ARCHIVE_ORDERS"
export const GET_ADMIN_ARCHIVE_ORDERS_SUCCESS = "GET_ADMIN_ARCHIVE_ORDERS_SUCCESS"
export const GET_ADMIN_ARCHIVE_ORDERS_FAILURE = "GET_ADMIN_ARCHIVE_ORDERS_FAILURE"

export const GET_ADMIN_ORDER_BY_ID = "GET_ADMIN_ORDER_BY_ID"
export const GET_ADMIN_ORDER_BY_ID_SUCCESS = "GET_ADMIN_ORDER_BY_ID_SUCCESS"
export const GET_ADMIN_ORDER_BY_ID_FAILURE = "GET_ADMIN_ORDER_BY_ID_FAILURE"

export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS"
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS"
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE"

export const CREATE_ORDER = "CREATE_ORDER"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE"

export const GET_ALL_TRANSACTION_LOG = "GET_ALL_TRANSACTION_LOG"
export const GET_ALL_TRANSACTION_LOG_SUCCESS = "GET_ALL_TRANSACTION_LOG_SUCCESS"
export const GET_ALL_TRANSACTION_LOG_FAILURE = "GET_ALL_TRANSACTION_LOG_FAILURE"

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS"
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS"
export const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE"

export const GET_ALL_SYSTEM_ADMIN = "GET_ALL_SYSTEM_ADMIN"
export const GET_ALL_SYSTEM_ADMIN_SUCCESS = "GET_ALL_SYSTEM_ADMIN_SUCCESS"
export const GET_ALL_SYSTEM_ADMIN_FAILURE = "GET_ALL_SYSTEM_ADMIN_FAILURE"

export const GET_ALL_INDEPENDENT_DISTRIBUTORSHIP = "GET_ALL_INDEPENDENT_DISTRIBUTORSHIP"
export const GET_ALL_INDEPENDENT_DISTRIBUTORSHIP_SUCCESS = "GET_ALL_INDEPENDENT_DISTRIBUTORSHIP_SUCCESS"
export const GET_ALL_INDEPENDENT_DISTRIBUTORSHIP_FAILURE = "GET_ALL_INDEPENDENT_DISTRIBUTORSHIP_FAILURE"

export const GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME = "GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME"
export const GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME_SUCCESS = "GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME_SUCCESS"
export const GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME_FAILURE = "GET_INDEPENDENT_DISTRIBUTOR_NEAR_ME_FAILURE"

export const GET_ALL_QUEUE_ORDERS = "GET_ALL_QUEUE_ORDERS"
export const GET_ALL_QUEUE_ORDERS_SUCCESS = "GET_ALL_QUEUE_ORDERS_SUCCESS"
export const GET_ALL_QUEUE_ORDERS_FAILURE = "GET_ALL_QUEUE_ORDERS_FAILURE"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS"
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE"

export const GET_USER_PROFILE_DETAILS = "GET_USER_PROFILE_DETAILS"
export const GET_USER_PROFILE_DETAILS_SUCCESS = "GET_USER_PROFILE_DETAILS_SUCCESS"
export const GET_USER_PROFILE_DETAILS_FAILURE = "GET_USER_PROFILE_DETAILS_FAILURE"

export const GET_PENDING_APPLICATION = "GET_PENDING_APPLICATION"
export const GET_PENDING_APPLICATION_SUCCESS = "GET_PENDING_APPLICATION_SUCCESS"
export const GET_PENDING_APPLICATION_FAILURE = "GET_PENDING_APPLICATION_FAILURE"

export const GET_APPROVED_APPLICATION = "GET_APPROVED_APPLICATION"
export const GET_APPROVED_APPLICATION_SUCCESS = "GET_APPROVED_APPLICATION_SUCCESS"
export const GET_APPROVED_APPLICATION_FAILURE = "GET_APPROVED_APPLICATION_FAILURE"

export const POST_ASSIGNED_DISTRIBUTOR = "POST_ASSIGNED_DISTRIBUTOR"
export const POST_ASSIGNED_DISTRIBUTOR_SUCCESS = "POST_ASSIGNED_DISTRIBUTOR_SUCCESS"
export const POST_ASSIGNED_DISTRIBUTOR_FAILURE = "POST_ASSIGNED_DISTRIBUTOR_FAILURE"

export const POST_PROFILE_PICTURE_AVATAR = "POST_PROFILE_PICTURE_AVATAR"
export const POST_PROFILE_PICTURE_AVATAR_SUCCESS = "POST_PROFILE_PICTURE_AVATAR_SUCCESS"
export const POST_PROFILE_PICTURE_AVATAR_FAILURE = "POST_PROFILE_PICTURE_AVATAR_FAILURE"

export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS"
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE"

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE"

export const CREATE_DISTRIBUTOR_CUSTOMER = "CREATE_DISTRIBUTOR_CUSTOMER"
export const CREATE_DISTRIBUTOR_CUSTOMER_SUCCESS = "CREATE_DISTRIBUTOR_CUSTOMER_SUCCESS"
export const CREATE_DISTRIBUTOR_CUSTOMER_FAILURE = "CREATE_DISTRIBUTOR_CUSTOMER_FAILURE"

export const CREATE_RETAILER_CUSTOMER = "CREATE_RETAILER_CUSTOMER"
export const CREATE_RETAILER_CUSTOMER_SUCCESS = "CREATE_RETAILER_CUSTOMER_SUCCESS"
export const CREATE_RETAILER_CUSTOMER_FAILURE = "CREATE_RETAILER_CUSTOMER_FAILURE"

export const CREATE_EXCLUSIVE_CUSTOMER = "CREATE_EXCLUSIVE_CUSTOMER"
export const CREATE_EXCLUSIVE_CUSTOMER_SUCCESS = "CREATE_EXCLUSIVE_CUSTOMER_SUCCESS"
export const CREATE_EXCLUSIVE_CUSTOMER_FAILURE = "CREATE_EXCLUSIVE_CUSTOMER_FAILURE"
//orders?page=1&order_status_id=1&user_id=9 = get a user order history loaded is 4 or loading is 4 as completed in api, pickup 5, archive=6, rejected 0

export const GET_PERMISSION = "GET_PERMISSION"
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS"
export const GET_PERMISSION_FAILURE = "GET_PERMISSION_FAILURE"

export const UPDATE_EMPLOYEE_DETAILS = "UPDATE_EMPLOYEE_DETAILS"
export const UPDATE_EMPLOYEE_DETAILS_SUCCESS = "UPDATE_EMPLOYEE_DETAILS_SUCCESS"
export const UPDATE_EMPLOYEE_DETAILS_FAILURE = "UPDATE_EMPLOYEE_DETAILS_FAILURE"
export const RESTORE_EMPLOYEE_DETAILS = "RESTORE_EMPLOYEE_DETAILS"


export const GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA"
export const GET_EMPLOYEE_DATA_SUCCESS = "GET_EMPLOYEE_DATA_SUCCESS"
export const GET_EMPLOYEE_DATA_FAILURE = "GET_EMPLOYEE_DATA_FAILURE"


export const ADD_ROLE_DETAILS = "ADD_ROLE_DETAILS"
export const RESTORE_ROLE_DETAILS = "RESTORE_ROLE_DETAILS"
export const CREATE_ROLE_WITH_PERMISSION = "CREATE_ROLE_WITH_PERMISSION"
export const CREATE_ROLE_WITH_PERMISSION_SUCCESS = "CREATE_ROLE_WITH_PERMISSION_SUCCESS"
export const CREATE_ROLE_WITH_PERMISSION_FAILURE = "CREATE_ROLE_WITH_PERMISSION_FAILURE"

export const RESTORE_DISTRIBUTOR_CREATION = "RESTORE_DISTRIBUTOR_CREATION"
export const RESTORE_RETAILER_CREATION = "RESTORE_RETAILER_CREATION"
export const RESTORE_EXCLUSIVE_CREATION = "RESTORE_EXCLUSIVE_CREATION"

export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE"

export const GET_GOOGLE_PLACES = "GET_GOOGLE_PLACES"
export const GET_GOOGLE_PLACES_SUCCESS = "GET_GOOGLE_PLACES_SUCCESS"
export const GET_GOOGLE_PLACES_FAILURE = "GET_GOOGLE_PLACES_FAILURE"


export const GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN"
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS"
export const GOOGLE_SIGN_IN_FAILURE = "GOOGLE_SIGN_IN_FAILURE"

export const GOOGLE_SIGN_UP = "GOOGLE_SIGN_UP"
export const GOOGLE_SIGN_UP_SUCCESS = "GOOGLE_SIGN_UP_SUCCESS"
export const GOOGLE_SIGN_UP_FAILURE = "GOOGLE_SIGN_UP_FAILURE"

export const UPDATE_ROLE_PERMISSION = "UPDATE_ROLE_PERMISSION"
export const UPDATE_ROLE_PERMISSION_SUCCESS = "UPDATE_ROLE_PERMISSION_SUCCESS"
export const UPDATE_ROLE_PERMISSION_FAILURE = "UPDATE_ROLE_PERMISSION_FAILURE"

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS"
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE"
export const RESET_USER_STATUS = "RESET_USER_STATUS"

export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS"
export const UPDATE_USER_DATA_FAILURE = "UPDATE_USER_DATA_FAILURE"

export const UPDATE_BUSINESS_DATA = "UPDATE_BUSINESS_DATA"
export const UPDATE_BUSINESS_DATA_SUCCESS = "UPDATE_BUSINESS_DATA_SUCCESS"
export const UPDATE_BUSINESS_DATA_FAILURE = "UPDATE_BUSINESS_DATA_FAILURE"

export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT"
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS"
export const DELETE_USER_ACCOUNT_FAILURE = "DELETE_USER_ACCOUNT_FAILURE"

export const GET_REJECTED_APPLICATION = "GET_REJECTED_APPLICATION"
export const GET_REJECTED_APPLICATION_SUCCESS = "GET_REJECTED_APPLICATION_SUCCESS"
export const GET_REJECTED_APPLICATION_FAILURE = "GET_REJECTED_APPLICATION_FAILURE"

export const REJECT_APPLICATION = "REJECT_APPLICATION"
export const REJECT_APPLICATION_SUCCESS = "REJECT_APPLICATION_SUCCESS"
export const REJECT_APPLICATION_FAILURE = "REJECT_APPLICATION_FAILURE"

export const SEARCH_CUSTOMERS ="SEARCH_CUSTOMERS"
export const SEARCH_CUSTOMERS_SUCCESS ="SEARCH_CUSTOMERS_SUCCESS"
export const SEARCH_CUSTOMERS_FAILURE ="SEARCH_CUSTOMERS_FAILURE"

export const SEARCH_TRANSACTION_LOGS ="SEARCH_TRANSACTION_LOGS"
export const SEARCH_TRANSACTION_LOGS_SUCCESS ="SEARCH_TRANSACTION_LOGS_SUCCESS"
export const SEARCH_TRANSACTION_LOGS_FAILURE ="SEARCH_TRANSACTION_LOGS_FAILURE"


export const SEARCH_ORDERS ="SEARCH_ORDERS"
export const SEARCH_ORDERS_SUCCESS ="SEARCH_ORDERS_SUCCESS"
export const SEARCH_ORDERS_FAILURE ="SEARCH_ORDERS_FAILURE"

export const SEARCH_ROLES ="SEARCH_ROLES"
export const SEARCH_ROLES_SUCCESS ="SEARCH_ROLES_SUCCESS"
export const SEARCH_ROLES_FAILURE ="SEARCH_ROLES_FAILURE"

export const RESET_CUSTOMER_DETAILS ="RESET_CUSTOMER_DETAILS"
export const RESET_ORDERS ="RESET_ORDERS"

export const DELETE_APPLICANT_DOCUMENT = "DELETE_APPLICANT_DOCUMENT"
export const DELETE_APPLICANT_DOCUMENT_SUCCESS = "DELETE_APPLICANT_DOCUMENT_SUCCESS"
export const DELETE_APPLICANT_DOCUMENT_FAILURE = "DELETE_APPLICANT_DOCUMENT_FAILURE"

export const EXPORT_TRANSACTION_LOGS = "EXPORT_TRANSACTION_LOGS"
export const EXPORT_TRANSACTION_LOGS_SUCCESS = "EXPORT_TRANSACTION_LOGS_SUCCESS"
export const EXPORT_TRANSACTION_LOGS_FAILURE = "EXPORT_TRANSACTION_LOGS_FAILURE"

export const RESET_EXPORT = "RESET_EXPORT";

export const EXPORT_ORDERS = "EXPORT_ORDERS"
export const EXPORT_ORDERS_SUCCESS = "EXPORT_ORDERS_SUCCESS"
export const EXPORT_ORDERS_FAILURE = "EXPORT_ORDERS_FAILURE"

export const CREATE_OPAY_PAYMENT = "CREATE_OPAY_PAYMENT"
export const CREATE_OPAY_PAYMENT_SUCCESS = "CREATE_OPAY_PAYMENT_SUCCESS"
export const CREATE_OPAY_PAYMENT_FAILURE = "CREATE_OPAY_PAYMENT_FAILURE"

export const RESET_PAYMENT = "RESET_PAYMENT"
