import { toast } from "react-toastify";
import api from "../../Service/api";

export const CreateOpayPaymentApi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            debugger
            const response = await api.post(`/opay/create-payment`, payload);
            if (response.status === 200) {
                debugger
                const redirectUrl = response.data?.data?.data?.cashierUrl;
                window.location.href = redirectUrl;
                return response.data;
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            throw error;
        }
    }
};